import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from "./pages/home/home";
import Auth from "./pages/metamask/auth";


const Paths = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route element = { <Home/> }  path="/" exact />
                <Route element = { <Auth/> }  path="/metamask/auth" exact/>
            </Routes>
        </BrowserRouter>
    )
}

export default Paths;
