import './auth.css'
import React, { useEffect, useState} from 'react'
import { ethers } from "ethers"
import { parseUnits, hexlify } from "ethers/lib/utils"
import useWebSocket from 'react-use-websocket'

function Auth() {
  let provider
  let signer

  const [account, setAccount] = useState('')

  useEffect(() => {
    loadApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { lastJsonMessage, sendMessage } = useWebSocket('wss://wsfutmeta.herokuapp.com', {
    onOpen: () => console.log(`Connected to App WS`),
    onMessage: () => {
      if (lastJsonMessage) {
        console.log(lastJsonMessage);
      }
    },
    queryParams: { 'token': '123456' },
    onError: (event) => { console.error(event); },
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000
  });

  async function loadApp() {
    provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    signer = provider.getSigner()
    if (!signer) window.location.reload()
    await provider.send("eth_requestAccounts", [])
    processAction()
  }

  function processAction() {
    const urlParams = new URLSearchParams(window.location.search)
    const action = urlParams.get("action")
    const initiator = urlParams.get("uid")
    const message = "Deseja logar no FutMeta?"
    const chainId = urlParams.get("chainId") || 1
    const to = urlParams.get("to")
    const value = urlParams.get("value")
    const data = urlParams.get("data") || ""
    const gasLimit = urlParams.get("gasLimit") || undefined
    const gasPrice = urlParams.get("gasPrice") || undefined

    if (action === "sign" && message) {
      return signMessage(message,initiator)
    }

    if (action === "send" && to && value) {
      return sendTransaction(chainId, to, value, gasLimit, gasPrice, data)
    }

    // displayResponse("Invalid URL")
  }

  async function sendTransaction(chainId, to, value, gasLimit, gasPrice, data) {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const network = await provider.getNetwork()
      if (network.chainId !== chainId) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${parseInt(chainId, 10).toString(16)}` }], // chainId must be in hexadecimal numbers
        })
      }
      const from = await signer.getAddress()
      const tx = await signer.sendTransaction({
        from,
        to,
        value: parseUnits(value, "wei"),
        gasLimit: gasLimit ? hexlify(Number(gasLimit)) : gasLimit,
        gasPrice: gasPrice ? hexlify(Number(gasPrice)) : gasPrice,
        data: data ? data : "0x",
      })
      console.log({ tx })
      displayResponse("Transaction sent.<br>Copy to clipboard then continue to App", tx.hash)
    } catch (error) {
      copyToClipboard("error")
      displayResponse("Transaction Denied")
    }
  }

  async function signMessage(message, initiator) {
    try {
      //https://docs.ethers.io/v5/concepts/
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const signature = await signer.signMessage(message)
      console.log({ signature })
      const from = await signer.getAddress()
      console.log({ from })
      setAccount(from)
      await sendMessage(`meta_auth|true|${initiator}|${from}|${signature}`)
      window.close();


      // displayResponse("Signature complete.<br>Copy to clipboard then continue to App", signature)
    } catch (error) {
      sendMessage(`meta_auth|false|${initiator}`)
    }
  }

  async function copyToClipboard(response) {
    try {
      // focus from metamask back to browser
      window.focus()
      // wait to finish focus
      await new Promise((resolve) => setTimeout(resolve, 500))
      // copy tx hash to clipboard
      await navigator.clipboard.writeText(response)
      document.getElementById("response-button").innerHTML = "Copied"
    } catch {
      // for metamask mobile android
      const input = document.createElement("input")
      input.type = "text"
      input.value = response
      document.body.appendChild(input)
      input.select()
      document.execCommand("Copy")
      input.style = "visibility: hidden"
      document.getElementById("response-button").innerHTML = "Copied"
    }
  }

  function displayResponse(text, response) {
    // display error or response
    const responseText = document.getElementById("response-text")
    responseText.innerHTML = text
    responseText.className = "active"

    if (response) {
      // display button to copy tx.hash or signature
      const responseButton = document.getElementById("response-button")
      responseButton.className = "active"
      responseButton.onclick = () => copyToClipboard(response)
    }
  }

  return (
      <div>
        Conta Logged: {account}
      </div>
  )
}

export default Auth
