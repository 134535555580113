import React from 'react';
import './home.css';

import logo from '../../assets/images/logo.png';
const Home = () =>{
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />

            </header>
            <div className="div-main">
                <p>coming soon</p>
            </div>
        </div>
    );
}

export default Home;
